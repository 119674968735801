<template>
  <div class="report">
    <div class="report_title">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>首页</el-breadcrumb-item>
        <el-breadcrumb-item>学习中心</el-breadcrumb-item>
        <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="title">
        <div class="name">{{ title }}</div>
        <div class="tip">
          试卷说明：本试卷共{{ reportObj.SubjectName }}道题，满分{{
            soure.fullPoints
          }}分，考试时间{{ soure.examTime }}分钟
        </div>
      </div>
    </div>
    <div class="report_details">
      <div class="details_left">
        <queCarPartner></queCarPartner>
      </div>


      <div class="details_right">
        <div class="time">
          <el-image class="img" :src="require('@/assets/img/partner/time3.png')"></el-image>
          <span class="time_title">总用时：{{ soure.answerTimes }}</span>
        </div>
        <div class="on">
          <el-image class="img" :src="require('@/assets/img/partner/report3.png')"></el-image>
          <span class="time_title">主观题不进行判分，请参照答案</span>
        </div>
        <div class="goback" @click="gobackClick">
          <el-image class="img" :src="require('@/assets/img/partner/goback.png')"></el-image>
          <span class="time_title bule">返回学习</span>
        </div>
        <div class="goback active">
          <el-image class="img" :src="require('@/assets/img/partner/report1.png')"></el-image>
          <span class="time_title">查看报告</span>
        </div>
        <div class="goback">
          <el-image class="img" :src="require('@/assets/img/partner/analysis2.png')"></el-image>
          <span class="time_title">全部解析</span>
        </div>
        <div class="goback">
          <el-image class="img" :src="require('@/assets/img/partner/analysis.png')"></el-image>
          <span class="time_title">错题解析</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AnswerClient } from "@/api/task/answerClient";
import queCarPartner from "./queCarPartner.vue";

var dayjs = require('dayjs')
const answerClient = new AnswerClient();

export default {
  components: {
    queCarPartner,
  },
  data() {
    return {
      title: "",
      paperId: "",
      examType: "",
      soure: {},
      paperObj: {},
      arrayList: [],
      reportObj: {},
    };
  },
  created() {
    this.title = this.$route.query.title;
    this.paperId = this.$route.query.paperId;
    this.examType = this.$route.query.examType;
    this.roomId = this.$route.query.roomId,
      this.getAnswerDetail();
  },
  methods: {
    // dateFormat(val) {
    //   console.log(val/1000);
    // },
    async getAnswerDetail() {
      const res = await answerClient.getPcNewSubjectAnswer(
        this.paperId,
        this.roomId,
        this.examType,
      );
      this.reportObj = res.data;
      this.paperObj = res.data.paper;
      this.arrayList = res.data.arrayList;
      this.soure = res.data.examResult;
    },
    gobackClick() {
      history.back();
    },
  },
};
</script>

<style lang="less" scoped>
.report {
  width: 100%;

  .report_title {
    margin: 20px auto 0;
    width: 1200px;
    // height: 114px;
    background: #ffffff;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    padding: 24px 32px 22px 29px;

    .title {
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .name {
        font-size: 20px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #333333;
      }

      .tip {
        font-size: 12px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #999999;
      }
    }
  }

  .report_details {
    width: 1200px;
    margin: 20px auto 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .details_left {
      width: 974px;
      height: 601px;
      background: #ffffff;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      padding: 40px 20px 26px;

      .top {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .top_img {
          padding: 0px 38px 0 20px;
          position: relative;

          .img {
            width: 251px;
            height: 160px;
          }

          .grade {
            position: absolute;
            top: 20%;
            left: 50%;
            width: 82px;
            margin-left: -51px;
            text-align: center;
            font-size: 50px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: bold;
            color: #fd7400;
          }

          .topic {
            font-size: 14px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
            position: absolute;
            bottom: 20px;
            left: 73px;
          }
        }

        .left_img {
          display: flex;
          margin-top: 50px;
          margin-left: 40px;

          .tip {
            margin-right: 49px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .subject {
              font-size: 16px;
              font-family: Source Han Sans CN-Normal, Source Han Sans CN;
              font-weight: 400;
              color: #999999;
            }

            .num {
              margin-top: 37px;
              font-size: 24px;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #333333;
            }
          }

          .tip:nth-last-child(1) {
            margin-right: 0;
          }
        }
      }

      .bottom {
        width: 934px;
        height: 338px;
        background: #f9fafa;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        margin-top: 37px;
        padding: 22px 20px 0;

        .bottom_title {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .title {
            font-size: 18px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            display: flex;
            align-items: center;

            .img {
              width: 18px;
              height: 18px;
              margin-right: 10px;
            }
          }

          .tip {
            display: flex;
            justify-content: center;
            align-items: center;

            .tip_item {
              display: flex;
              justify-content: center;
              align-items: center;

              .name {
                font-size: 12px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #999999;
                margin-right: 2px;
              }

              .dian1 {
                width: 10px;
                height: 10px;
                background: #13bc75;
                opacity: 1;
                border-radius: 50%;
                margin-right: 10px;
              }

              .dian2 {
                width: 10px;
                height: 10px;
                background: #f55523;
                opacity: 1;
                border-radius: 50%;
                margin-right: 10px;
              }

              .dian3 {
                width: 10px;
                height: 10px;
                background: #ffffff;
                opacity: 1;
                border: 1px solid #dddddd;
                border-radius: 50%;
                margin-right: 10px;
              }

              .dian4 {
                width: 10px;
                height: 10px;
                background: #30a7e5;
                opacity: 1;
                border-radius: 50%;
              }
            }
          }
        }

        .content {
          .single {
            margin-top: 34px;

            .single_title {
              font-size: 14px;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #666666;

              .tip {
                font-size: 12px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #999999;
              }
            }

            .single_num {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              flex-wrap: wrap;

              .num_item1,
              .num_item2,
              .num_item3,
              .num_item4 {
                margin-top: 14px;
                width: 34px;
                height: 34px;
                border-radius: 3px 3px 3px 3px;
                opacity: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #ffffff;
                margin-right: 20px;
              }

              .num_item1 {
                background: #13bc75;
              }

              .num_item2 {
                background: #ee5729;
              }

              .num_item3 {
                background: #ffffff;
                border: 1px solid #dddddd;
                color: #999999;
              }

              .num_item4 {
                background: #30a7e5;
                color: #fff;
              }
            }
          }
        }
      }
    }

    .details_right {
      width: 207px;
      height: 300px;
      background: #ffffff;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;

      .time {
        width: 207px;
        height: 60px;
        background: #f9fbff;
        border-radius: 6px 6px 0px 0px;
        opacity: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        .img {
          width: 14px;
          height: 14px;
          margin-right: 5px;
        }

        .time_title {
          font-size: 14px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #666666;
        }
      }

      .goback {
        width: 207px;
        height: 50px;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        .img {
          width: 14px;
          height: 14px;
          margin-right: 10px;
        }

        .time_title {
          font-size: 16px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #333333;
        }

        .bule {
          color: #1c81f5;
        }
      }

      .goback:nth-last-child(1) {
        border-top: 1px solid #eeeeee;
      }

      .active {
        background: #1c81f5;

        .time_title {
          color: #ffffff;
        }
      }

      .on {
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(255, 93, 81, 0.1);

        .img {
          width: 12px;
          height: 13px;
          margin-right: 6px;
        }

        .time_title {
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #ff5d51;
          padding: 10px 0;
        }
      }
    }
  }
}
</style>
